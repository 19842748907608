import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Jumbotron from "components/Jumbotron";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "jumbotron"
    }}>{`Jumbotron`}</h1>

    <Playground __position={0} __code={'<Jumbotron>\n  <h1 className=\"display-3\">Hello, world!</h1>\n  <p className=\"lead\">\n    This is a simple hero unit, a simple Jumbotron-style component for calling\n    extra attention to featured content or information.\n  </p>\n  <hr className=\"my-2\" />\n  <p>\n    It uses utility classes for typography and spacing to space content out\n    within the larger container.\n  </p>\n  <p className=\"lead\">\n    <a className=\"btn btn-primary\">Learn More</a>\n  </p>\n</Jumbotron>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Jumbotron,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Jumbotron mdxType="Jumbotron">
    <h1 className="display-3">Hello, world!</h1>
    <p className="lead">This is a simple hero unit, a simple Jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr className="my-2" />
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <p className="lead">
      <a className="btn btn-primary">Learn More</a>
    </p>
  </Jumbotron>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/jumbotron/" target="_blank">Reactstrap Jumbotron</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      